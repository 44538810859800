import gql from 'graphql-tag';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const ReceptionStatusBaseQueryResultFragmentDoc = gql`
    fragment receptionStatusBaseQueryResult on Query {
  adminId: getSessionAdminId
  managerId: getSessionManagerId
  practiceId: getSessionPracticeId
}
    `;
export const ActorOverviewFragmentDoc = gql`
    fragment actorOverview on Actor {
  actorId
  deletedAt
  timeLastActive
  adminDashboardSettings
  managerDashboardSettings
  managerAppointmentSortDirection
  managerNotifyTimeInReceptionDelays
  managerNotifyTimeInReceptionDelaysMethod
  managerNotifyTimeInReceptionDelaysAfterMinutes
  managerIgnoreTimeInReceptionEarlyCheckIn
  managerNotifyWaitForIntakeDelays
  managerNotifyWaitForIntakeDelaysMethod
  managerNotifyWaitForIntakeDelaysAfterMinutes
  managerNotifyWaitForDoctorDelays
  managerNotifyWaitForDoctorDelaysMethod
  managerNotifyWaitForDoctorDelaysAfterMinutes
  managerDisplayPatientInitialsOnly
  managerStatusSiteAutoSwipeDelaySeconds
  managerGroupAppointmentsByProvider
  managerDisplayArrowStateDurations
  managerNotifyCancellations
  managerDisplayCancellations
  managerDisplayCancellationsWithinHours
  managerDisplayPatientLocation
  managerDisplayIndicatorAfterPracticeNoShowCount
  managerDisplayIndicatorAfterPracticeSameDayCancellationCount
  managerTimeLastRequestedFeedback
  providerGroups {
    name
    providerIds
  }
}
    `;
export const AdminOverviewFragmentDoc = gql`
    fragment adminOverview on Admin {
  adminId
  email
  phoneNumber
  firstName
  lastName
  created
  updated
  nameDisplay
}
    `;
export const AdminDetailFragmentDoc = gql`
    fragment adminDetail on Admin {
  adminId
  adminActor {
    ...actorOverview
  }
  ...adminOverview
}
    ${ActorOverviewFragmentDoc}
${AdminOverviewFragmentDoc}`;
export const ReceptionStatusManagerAdminBaseQueryResultFragmentDoc = gql`
    fragment receptionStatusManagerAdminBaseQueryResult on Query {
  admin(adminId: $adminId) {
    ...adminDetail
  }
}
    ${AdminDetailFragmentDoc}`;
export const ReceptionStatusManagerAutoSwipeDelayQueryResultFragmentDoc = gql`
    fragment receptionStatusManagerAutoSwipeDelayQueryResult on Query {
  actors(condition: {typeBasedActorId: $typeBasedActorId}) {
    nodes {
      actorId
      managerStatusSiteAutoSwipeDelaySeconds
    }
  }
}
    `;
export const ManagerPermissionsFragmentDoc = gql`
    fragment managerPermissions on Manager {
  canAccessCheckoutTable
  canAccessReceptionStatusSite
  canAccessInsights
  canAccessMonthlyReport
  canAccessBilling
  canAccessPatientFlowReport
}
    `;
export const ManagerProviderPermissionFragmentDoc = gql`
    fragment managerProviderPermission on ManagerProviderPermission {
  managerProviderPermissionId
  providerId
  managerId
  practiceId
  departmentId
  created
  updated
}
    `;
export const ManagerOverviewFragmentDoc = gql`
    fragment managerOverview on Manager {
  managerId
  practiceId
  email
  username
  firstName
  lastName
  phoneNumber
  sex
  eulaTimestamp
  nameDisplay
  isPracticeAdmin
  created
  updated
  dateTrialEnd
  tableauManagerId
  ...managerPermissions
  managerProviderPermissions {
    nodes {
      ...managerProviderPermission
    }
  }
  managerActor {
    ...actorOverview
  }
}
    ${ManagerPermissionsFragmentDoc}
${ManagerProviderPermissionFragmentDoc}
${ActorOverviewFragmentDoc}`;
export const ReceptionStatusManagerOverviewQueryResultFragmentDoc = gql`
    fragment receptionStatusManagerOverviewQueryResult on Query {
  manager(managerId: $managerId) {
    ...managerOverview
  }
}
    ${ManagerOverviewFragmentDoc}`;
export const ReceptionStatusProviderDelayTimesQueryResultFragmentDoc = gql`
    fragment receptionStatusProviderDelayTimesQueryResult on Query {
  providerDelays(condition: {practiceId: $practiceId}) {
    nodes {
      providerId
      providerMinutesLate
    }
  }
}
    `;
export const ReceptionStatusPracticeFragmentDoc = gql`
    fragment receptionStatusPractice on Practice {
  practiceId
  name
}
    `;
export const ReceptionStatusProviderFragmentDoc = gql`
    fragment receptionStatusProvider on Provider {
  providerId
  displayName
}
    `;
export const ReceptionStatusProvidersQueryResultFragmentDoc = gql`
    fragment receptionStatusProvidersQueryResult on Query {
  practice(practiceId: $practiceId) {
    ...receptionStatusPractice
  }
  providers(condition: {practiceId: $practiceId}) {
    nodes {
      ...receptionStatusProvider
    }
  }
}
    ${ReceptionStatusPracticeFragmentDoc}
${ReceptionStatusProviderFragmentDoc}`;
export const GlobalProviderDashboardWarningNotifierSubscriptionResultFragmentDoc = gql`
    fragment globalProviderDashboardWarningNotifierSubscriptionResult on Subscription {
  globalProviderDashboardWarning {
    event
  }
}
    `;
export const PracticeProviderDashboardWarningNotifierSubscriptionResultFragmentDoc = gql`
    fragment practiceProviderDashboardWarningNotifierSubscriptionResult on Subscription {
  practiceProviderDashboardWarning {
    event
  }
}
    `;
export const ActorDelayConfigsFragmentDoc = gql`
    fragment actorDelayConfigs on Actor {
  actorId
  managerNotifyTimeInReceptionDelays
  managerNotifyTimeInReceptionDelaysMethod
  managerNotifyTimeInReceptionDelaysAfterMinutes
  managerIgnoreTimeInReceptionEarlyCheckIn
  managerNotifyWaitForIntakeDelays
  managerNotifyWaitForIntakeDelaysMethod
  managerNotifyWaitForIntakeDelaysAfterMinutes
  managerNotifyWaitForDoctorDelays
  managerNotifyWaitForDoctorDelaysMethod
  managerNotifyWaitForDoctorDelaysAfterMinutes
}
    `;
export const AppointmentEstimateFragmentDoc = gql`
    fragment appointmentEstimate on AppointmentEstimate {
  appointmentId
  estWaitForCheckoutTime
  estCheckoutTime
  estCheckInTime
  estWaitForIntakeTime
  estIntakeTime
  estWaitForDoctorTime
  estInExamTime
  estCheckoutEndTime
  suggestedArrivalTime
  oneHourPrevSuggestedArrivalTime
}
    `;
export const PracticePatientLocationFragmentDoc = gql`
    fragment practicePatientLocation on PracticePatientLocation {
  practicePatientLocationId
  practiceId
  departmentId
  name
  usedForCheckIn
  usedForWaitForIntake
  usedForIntake
  usedForWaitForDoctor
  usedForExam
  usedForWaitForCheckout
  usedForCheckingOut
  defaultOnCheckIn
}
    `;
export const AppointmentOverviewSelfFragmentDoc = gql`
    fragment appointmentOverviewSelf on Appointment {
  appointmentId
  providerId
  scheduleProviderId
  renderingProviderId
  practiceId
  departmentId
  patientId
  appointmentTypeId
  srcType
  status
  appointmentTypeName
  patientAppointmentTypeName
  patientManualCheckIn
  timeScheduled
  timeCreated
  timeUpdated
  timeCheckIn
  timeCheckInStart
  timeCheckInEnd
  timeIntakeEnd
  timeIntakeEndDerived
  timeIntakeStart
  timeExamStart
  timeExamEnd
  timeExamEndCompiled
  timeCheckOutStart
  timeCheckOutEnd
  timeCancelled
  timeFirstTexted
  timeFirstTextedSuggestedArrivalTime
  timeLatestTexted
  timeLatestTextedSuggestedArrivalTime
  urgent
  cancelReasonId
  cancelledBy
  timeEncounterCheckedOut
  timeEnd
  timeReadyForProvider
  timeReadyForStaff
  pausedAt
  lastStateMachineStatusChange
  patientManualCheckIn
  roomReady
  duration
  autoPausedAt
  rescheduledAppointmentId
  confirmationStatusId
  confirmationStatusName
  created
  updated
  readyForCheckInBroadcastAt
  roomReadyButtonClickedAt
  timeDoneButtonPressed
  timeDrsLinkSent
  encounterId
  timeChartIntakeStart
  timeChartExamStart
  timeChartExamEnd
  timeIntakeEndDerived
  timeWaitForIntakeStartInferredFromLocation
  timeIntakeStartInferredFromLocation
  timeReadyForProviderInferredFromLocation
  timeExamStartInferredFromLocation
  timeWaitForCheckoutStartInferredFromLocation
  timeCheckOutStartInferredFromLocation
  timeCheckInStartFhirInferred
  timeIntakeStartFhirInferred
  timeIntakeEndFhirInferred
  timeExamStartFhirInferred
  timeExamEndFhirInferred
  timeCheckOutStartFhirInferred
  timeCheckInStartCompiled
  timeIntakeStartCompiled
  timeIntakeEndCompiled
  timeExamStartCompiled
  timeExamEndCompiled
  timeCheckedOutCompiled
  appointmentEstimate {
    ...appointmentEstimate
  }
  practicePatientLocation {
    ...practicePatientLocation
  }
  isDone
  isPaused
  isAutoPaused
  isInLagState
  isNotSeen
  isNotStarted
  isActualEndTimeIndeterminate
  isActualEndTimeDeterminate
  canBePaused
  calculatedAppointmentStatus
  statusString
  timelineIndex
  _timeCheckInStart
  _timeCheckInEnd
  _timeIntakeStart
  _timeIntakeEnd
  _timeExamStart
  _timeExamEnd
  _timeCheckOutStart
  _timeCheckOutEnd
}
    ${AppointmentEstimateFragmentDoc}
${PracticePatientLocationFragmentDoc}`;
export const AppointmentTextFragmentDoc = gql`
    fragment appointmentText on AppointmentText {
  appointmentTextId
  phoneNumber
  appointmentId
  twilioResponse
  created
  messageBody
}
    `;
export const AppointmentTokenSessionFragmentDoc = gql`
    fragment appointmentTokenSession on AppointmentTokenSession {
  appointmentId
  appointmentToken
  liveUrl
  timePatientSiteFirstVisited
  created
  updated
  expire
}
    `;
export const PhoneNumberFragmentDoc = gql`
    fragment phoneNumber on TtPhoneNumber {
  type
  number
}
    `;
export const AddressFragmentDoc = gql`
    fragment address on TtAddress {
  street1
  street2
  city
  state
  zip
  county
  country
  addressString
}
    `;
export const PatientScheduleProviderSameDayNoShowCountFragmentDoc = gql`
    fragment patientScheduleProviderSameDayNoShowCount on PatientScheduleProviderSameDayNoShowCount {
  practiceId
  patientId
  totalCount
  maxSameDayCancellationDateScheduled
  minSameDayCancellationDateScheduled
  sameDayCancellationCount
  maxNoShowDateScheduled
  minNoShowDateScheduled
  noShowCount
}
    `;
export const PatientOverviewFragmentDoc = gql`
    fragment patientOverview on Patient {
  patientId
  srcType
  firstName
  middleName
  lastName
  dob
  nameInitials
  nameDisplay
  firstNameDisplay
  fullNameDisplay
  ageInYears
  suffix
  preferredName
  sex
  consentToCall
  consentToText
  cannotSmsReason
  canSms
  emailAddresses
  languages
  created
  updated
  phoneNumbers {
    ...phoneNumber
  }
  address {
    ...address
  }
  patientScheduleProviderSameDayNoShowCounts {
    nodes {
      ...patientScheduleProviderSameDayNoShowCount
    }
  }
}
    ${PhoneNumberFragmentDoc}
${AddressFragmentDoc}
${PatientScheduleProviderSameDayNoShowCountFragmentDoc}`;
export const ProviderDepartmentOptionCompiledFragmentDoc = gql`
    fragment providerDepartmentOptionCompiled on ProviderDepartmentOptionsCompiled {
  providerId
  practiceId
  departmentId
  appointmentTypeId
  showCheckInButtonInVwr
  showSuggestedArrivalTimeInVwr
  anchorAutoPauseToSuggestedArrivalTime
  patientSiteShowCallButton
  patientSiteShowIntakeForm
  patientSiteShowLocation
  patientSiteIntakeFormLink
  patientSiteContactTranslationId
  patientSiteDirectionsTranslationId
  patientSiteRemoteDirectionsTranslationId
  appointmentPlaceholderMessageTranslationId
  appointmentScheduledMessageTranslationId
  appointmentReminderMessageTranslationId
  appointmentDelayedMessageTranslationId
  appointmentRoomReadyMessageTranslationId
  patientSiteFaqTranslationId
  patientSiteIntakeFormUrlTranslationId
  patientSiteGettingToLinkATranslationId
  patientSiteGettingToLinkBTranslationId
  patientSiteBeforeLinkATranslationId
  patientSiteBeforeLinkBTranslationId
  patientSiteArrivedLinkATranslationId
  patientSiteArrivedLinkBTranslationId
  patientSiteAboutLinkATranslationId
  patientSiteAboutLinkBTranslationId
  patientSiteAfterLinkATranslationId
  patientSiteAfterLinkBTranslationId
  googlePlaceId
  suggestedArrivalTimeMaxDriftMinutes
  patientSiteDisplayPaymentPortal
  patientSiteDisplayPatientPortal
  appointmentsHideOnStatus
  appointmentsHideOnManager
  appointmentsDisableDelayCalc
  patientSiteDisplayLatestSmsSuggestedArrivalTime
  patientSitePracticeSlugId
  autoPauseAfterMinutes
  forcePauseAfterMinutes
  minMinutesBetweenReminderTexts
  patientSiteAboutVideoLinkTranslationId
  useRenderingProvider
  statsType
  persistCheckInToEhr
  useStatsV2
  useMLDurations
  excludeFromViewsAndQueues
  appointmentsAutoTextingDisabled
  noSmsBeforeMinutesIntoDay
  pollPatientChartsOnWaitForIntakeVitals
  pollPatientChartsOnWaitForIntakeAllergies
  pollPatientChartsOnWaitForIntakeEncounterReasons
  pollPatientChartsOnWaitForIntakeProblems
  pollPatientChartsOnWaitForDoctorHpi
  pollPatientChartsOnWaitForDoctorOrders
  pollPatientChartsOnWaitForDoctorEncounterReasons
  pollPatientChartsOnWaitForDoctorReviewOfSystem
  pollPatientChartsOnExamDiagnosis
  canRollbackStatus
  useBetaOverbookedStackingBehavior
  useStatusInferenceByLocation
}
    `;
export const AppointmentCycleConfigCompiledFragmentDoc = gql`
    fragment appointmentCycleConfigCompiled on AppointmentCycleConfigCompiled {
  practiceId
  departmentId
  appointmentTypeId
  providerId
  checkIn
  waitForIntake
  intake
  waitForExam
  exam
  waitForCheckout
  waitForCheckOut: waitForCheckout
  checkout
  checkOut: checkout
}
    `;
export const AppointmentTypeProviderStatFragmentDoc = gql`
    fragment appointmentTypeProviderStat on AppointmentTypesProviderStat {
  providerId
  appointmentTypeId
  estimatedDuration
  checkInPredictedDuration
  waitForIntakePredictedDuration
  intakePredictedDuration
  waitForDoctorPredictedDuration
  waitForExamPredictedDuration: waitForDoctorPredictedDuration
  inExamPredictedDuration
  examPredictedDuration: inExamPredictedDuration
  waitForCheckoutPredictedDuration
  waitForCheckOutPredictedDuration: waitForCheckoutPredictedDuration
  checkOutPredictedDuration
}
    `;
export const StatsCompiledFragmentDoc = gql`
    fragment statsCompiled on StatsCompiled {
  intakePredictedDuration
  inExamPredictedDuration
  checkOutPredictedDuration
  checkInPredictedDuration
  waitForIntakePredictedDuration
  waitForDoctorPredictedDuration
  waitForCheckOutPredictedDuration
}
    `;
export const MlPredictedDurationsFragmentDoc = gql`
    fragment mlPredictedDurations on AppointmentPredictedDuration {
  checkInPredictedDuration
  waitForIntakePredictedDuration
  intakePredictedDuration
  waitForDoctorPredictedDuration
  inExamPredictedDuration
  waitForCheckOutPredictedDuration
  checkOutPredictedDuration
}
    `;
export const AppointmentStatesFragmentDoc = gql`
    fragment appointmentStates on AppointmentStateInts {
  checkIn
  waitForIntake
  intake
  waitForExam
  exam
  waitForCheckout
  checkout
}
    `;
export const AppointmentOverviewFragmentDoc = gql`
    fragment appointmentOverview on Appointment {
  ...appointmentOverviewSelf
  nextAppointmentId
  nextAppointment {
    ...appointmentOverviewSelf
    provider {
      providerId
      displayName
    }
  }
  previousAppointmentId
  previousAppointment {
    ...appointmentOverviewSelf
    provider {
      providerId
      displayName
    }
  }
  hl7ProviderIdUsed
  appointmentTexts(orderBy: CREATED_ASC) {
    nodes {
      ...appointmentText
    }
  }
  appointmentTokenSession {
    ...appointmentTokenSession
  }
  patient {
    ...patientOverview
  }
  providerDepartmentOptionCompiled {
    ...providerDepartmentOptionCompiled
  }
  appointmentCycleConfigCompiled {
    ...appointmentCycleConfigCompiled
  }
  appointmentTypeProviderStat {
    ...appointmentTypeProviderStat
  }
  statsCompiled {
    ...statsCompiled
  }
  appointmentPredictedDuration {
    ...mlPredictedDurations
  }
  completedStateDurations {
    ...appointmentStates
  }
}
    ${AppointmentOverviewSelfFragmentDoc}
${AppointmentTextFragmentDoc}
${AppointmentTokenSessionFragmentDoc}
${PatientOverviewFragmentDoc}
${ProviderDepartmentOptionCompiledFragmentDoc}
${AppointmentCycleConfigCompiledFragmentDoc}
${AppointmentTypeProviderStatFragmentDoc}
${StatsCompiledFragmentDoc}
${MlPredictedDurationsFragmentDoc}
${AppointmentStatesFragmentDoc}`;
export const AppointmentTypeOverviewFragmentDoc = gql`
    fragment appointmentTypeOverview on AppointmentType {
  appointmentTypeId
  srcType
  practiceId
  name
  nameShort
  nameDisplay
  duration
  estimatedDuration
  created
  updated
  remote
  intakeRequiresExamRoom
  examRequiresExamRoom
  multiDay
  appointmentPlaceholderMessageTranslationId
}
    `;
export const ProviderOverviewFragmentDoc = gql`
    fragment providerOverview on Provider {
  providerId
  practiceId
  displayName
  examRoomCount
  fetchAppointmentsEnabled
  autoTextingEnabledAfter
  doctorCount
  firstName
  lastName
  sex
  username
  npi
  phoneNumber
  created
  updated
  srcType
  isTextReminderDisabledToday
  excludeFromAppointmentTypeCalculations
  timeOnboarded
}
    `;
export const DepartmentWorkingHourFragmentDoc = gql`
    fragment departmentWorkingHour on DepartmentWorkingHour {
  departmentWorkingHourId
  practiceId
  departmentId
  overrideDate
  dayOfWeek
  startOfDayMinutes
  endOfDayMinutes
}
    `;
export const GooglePlaceOverviewFragmentDoc = gql`
    fragment googlePlaceOverview on GooglePlace {
  googlePlaceId
  placeId
  name
  formattedAddress
  adrAddress
  types
  url
  utcOffset
  vicinity
  icon
  openingHours {
    periods {
      open {
        day
        time
      }
      close {
        day
        time
      }
    }
  }
  addressComponents {
    longName
    shortName
    types
  }
  geometry {
    location {
      lat
      lng
    }
    viewport {
      northeast {
        lat
        lng
      }
      southwest {
        lat
        lng
      }
    }
  }
}
    `;
export const DepartmentOverviewFragmentDoc = gql`
    fragment departmentOverview on Department {
  departmentId
  practiceId
  name
  publicName
  timezone
  phoneNumber
  created
  updated
  srcType
  departmentWorkingHours(filter: {overrideDate: {isNull: true}}) {
    nodes {
      ...departmentWorkingHour
    }
  }
  googlePlace {
    ...googlePlaceOverview
  }
  address {
    ...address
  }
  srcType
}
    ${DepartmentWorkingHourFragmentDoc}
${GooglePlaceOverviewFragmentDoc}
${AddressFragmentDoc}`;
export const PracticeOverviewFragmentDoc = gql`
    fragment practiceOverview on Practice {
  practiceId
  name
  description
  status
  ownerId
  phoneNumbers {
    ...phoneNumber
  }
  address {
    ...address
  }
  practicesAthena {
    athenaPracticeId
  }
  srcType
  waitingRoomSeats
  patientRoomCount
  employeeCount
  retainEstimationsForMinutes
  created
  timezone
  updated
  defaultDelayedMessageTranslationId
  defaultReminderMessageTranslationId
  defaultPlaceholderMessageTranslationId
  defaultRoomReadyMessageTranslationId
  defaultScheduledMessageTranslationId
  managerDashboardUrl
  patientDashboardUrl
  receptionStatusDashboardUrl
  slug
  includeIntoAppointmentsChangedFetch
  lookForOrphanAppointments
}
    ${PhoneNumberFragmentDoc}
${AddressFragmentDoc}`;
export const AppointmentDetailFragmentDoc = gql`
    fragment appointmentDetail on Appointment {
  ...appointmentOverview
  appointmentType {
    ...appointmentTypeOverview
  }
  provider {
    ...providerOverview
  }
  department {
    ...departmentOverview
  }
  practice {
    ...practiceOverview
  }
}
    ${AppointmentOverviewFragmentDoc}
${AppointmentTypeOverviewFragmentDoc}
${ProviderOverviewFragmentDoc}
${DepartmentOverviewFragmentDoc}
${PracticeOverviewFragmentDoc}`;
export const AppointmentTimeseriesStatsFragmentDoc = gql`
    fragment appointmentTimeseriesStats on Appointment {
  ...appointmentDetail
  appointmentEstimateSnapshots(orderBy: [CAPTURE_TIME_ASC]) {
    nodes {
      captureTime
      suggestedArrivalTime
      estIntakeTime
    }
  }
}
    ${AppointmentDetailFragmentDoc}`;
export const AppointmentTypePredictedDurationsFragmentDoc = gql`
    fragment appointmentTypePredictedDurations on AppointmentType {
  appointmentTypeId
  estimatedDuration
  checkInPredictedDuration
  waitForIntakePredictedDuration
  intakePredictedDuration
  waitForDoctorPredictedDuration
  waitForExamPredictedDuration: waitForDoctorPredictedDuration
  inExamPredictedDuration
  examPredictedDuration: inExamPredictedDuration
  waitForCheckoutPredictedDuration
  waitForCheckOutPredictedDuration: waitForCheckoutPredictedDuration
  checkOutPredictedDuration
}
    `;
export const AppointmentTypeTemplatesTranslationsFragmentDoc = gql`
    fragment appointmentTypeTemplatesTranslations on AppointmentType {
  templateId
}
    `;
export const AdminAppointmentTypeDetailFragmentDoc = gql`
    fragment adminAppointmentTypeDetail on AppointmentType {
  ...appointmentTypeOverview
  ...appointmentTypePredictedDurations
  ...appointmentTypeTemplatesTranslations
  recentAppointmentCountsPerHours {
    aggregates {
      sum {
        count
      }
      distinctCount {
        departmentId
        providerId
      }
    }
  }
}
    ${AppointmentTypeOverviewFragmentDoc}
${AppointmentTypePredictedDurationsFragmentDoc}
${AppointmentTypeTemplatesTranslationsFragmentDoc}`;
export const ManagerPasswordResetTokenFragmentDoc = gql`
    fragment managerPasswordResetToken on ManagerPasswordResetToken {
  managerPasswordResetTokenId
  token
  expiresAt
  usedAt
  managerId
}
    `;
export const AdminManagerDetailFragmentDoc = gql`
    fragment adminManagerDetail on Manager {
  ...managerOverview
  eulaTimestamp
  created
  updated
  eulaTimestamp
  tableauManager {
    managerId
    username
    email
  }
  managerPasswordResetTokens {
    nodes {
      ...managerPasswordResetToken
    }
  }
  managerProviderPermissions {
    nodes {
      ...managerProviderPermission
    }
    aggregates {
      distinctCount {
        providerId
        departmentId
      }
    }
  }
}
    ${ManagerOverviewFragmentDoc}
${ManagerPasswordResetTokenFragmentDoc}
${ManagerProviderPermissionFragmentDoc}`;
export const ProviderDepartmentAppointmentCountFragmentDoc = gql`
    fragment providerDepartmentAppointmentCount on ProviderDepartmentAppointmentCount {
  providerId
  departmentId
  status
  appointmentCount
  managerAppointmentCount
  practiceId
  dateScheduled
}
    `;
export const ProviderAppointmentsCountFragmentDoc = gql`
    fragment providerAppointmentsCount on Provider {
  providerId
  providerDepartmentAppointmentCounts(
    filter: {dateScheduled: {equalTo: $dayStart}, status: {notIn: ["CANCELED", "LEFT_WITHOUT_BEING_SEEN", "NO_SHOW"]}}
  ) {
    nodes {
      ...providerDepartmentAppointmentCount
    }
  }
}
    ${ProviderDepartmentAppointmentCountFragmentDoc}`;
export const ProviderDetailFragmentDoc = gql`
    fragment providerDetail on Provider {
  ...providerOverview
  ...providerAppointmentsCount
}
    ${ProviderOverviewFragmentDoc}
${ProviderAppointmentsCountFragmentDoc}`;
export const ProviderDepartmentPaidConversionFragmentDoc = gql`
    fragment providerDepartmentPaidConversion on ProviderDepartmentPaidConversion {
  providerDepartmentPaidConversionId
  practiceId
  providerId
  departmentId
  dateTrialStart
  dateTrialEnd
  datePaidStart
  datePaidEnd
  created
  updated
}
    `;
export const AdminProviderDetailFragmentDoc = gql`
    fragment adminProviderDetail on Provider {
  ...providerDetail
  ...providerAppointmentsCount
  managerProviderPermissions {
    aggregates {
      distinctCount {
        managerId
      }
    }
  }
  recentAppointmentCountsPerHours {
    aggregates {
      sum {
        count
      }
      distinctCount {
        appointmentTypeId
        departmentId
      }
    }
    groupedByApptType: groupedAggregates(groupBy: [APPOINTMENT_TYPE_ID]) {
      keys
      sum {
        count
      }
    }
    groupedByDepartment: groupedAggregates(groupBy: [DEPARTMENT_ID]) {
      keys
      sum {
        count
      }
    }
  }
  providerDepartmentPaidConversions {
    nodes {
      ...providerDepartmentPaidConversion
    }
  }
}
    ${ProviderDetailFragmentDoc}
${ProviderAppointmentsCountFragmentDoc}
${ProviderDepartmentPaidConversionFragmentDoc}`;
export const AppointmentCycleConfigFragmentDoc = gql`
    fragment appointmentCycleConfig on AppointmentCycleConfig {
  appointmentCycleConfigId
  practiceId
  departmentId
  providerId
  appointmentTypeId
  step
  config
  created
  updated
  priority
  estimatedAdjustByMax
  estimatedAdjustmentByPercent
  estimatedMax
  estimatedPercentage
}
    `;
export const AppointmentCycleDefaultValueFragmentDoc = gql`
    fragment appointmentCycleDefaultValue on AppointmentCycleDefaultValue {
  appointmentCycleDefaultValueId
  practiceId
  departmentId
  providerId
  appointmentTypeId
  step
  minutesDuration
  created
  updated
  priority
}
    `;
export const AppointmentCalculationsWithNowFragmentDoc = gql`
    fragment appointmentCalculationsWithNow on AppointmentCalculationsWithNow {
  appointmentId
  checkInDurationMinutes
  waitForIntakeDurationMinutes
  intakeDurationMinutes
  waitForExamDurationMinutes
  examDurationMinutes
  waitForCheckOutDurationMinutes
  checkOutDurationMinutes
}
    `;
export const AppointmentDurationsCompiledFragmentDoc = gql`
    fragment appointmentDurationsCompiled on AppointmentDurationsCompiled {
  appointmentId
  checkInPredictedDuration
  waitForIntakePredictedDuration
  intakePredictedDuration
  waitForExamPredictedDuration
  examPredictedDuration
  waitForCheckOutPredictedDuration
  checkOutPredictedDuration
}
    `;
export const DepartmentsPracticePatientLocationFragmentDoc = gql`
    fragment departmentsPracticePatientLocation on DepartmentsPracticePatientLocation {
  departmentId
  practicePatientLocationId
}
    `;
export const DepartmentDetailFragmentDoc = gql`
    fragment departmentDetail on Department {
  ...departmentOverview
  departmentsPracticePatientLocations {
    nodes {
      ...departmentsPracticePatientLocation
    }
  }
  recentAppointmentCountsPerHours {
    aggregates {
      sum {
        count
      }
      distinctCount {
        appointmentTypeId
        providerId
      }
    }
  }
}
    ${DepartmentOverviewFragmentDoc}
${DepartmentsPracticePatientLocationFragmentDoc}`;
export const ManagerDetailFragmentDoc = gql`
    fragment managerDetail on Manager {
  ...managerOverview
}
    ${ManagerOverviewFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment pageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const PatientAppointmentEstimateFragmentDoc = gql`
    fragment patientAppointmentEstimate on AppointmentEstimate {
  appointmentId
  suggestedArrivalTime
  estIntakeTime
}
    `;
export const PatientAppointmentFragmentDoc = gql`
    fragment patientAppointment on Appointment {
  appointmentId
  timeScheduled
  appointmentEstimate {
    ...patientAppointmentEstimate
  }
}
    ${PatientAppointmentEstimateFragmentDoc}`;
export const PatientDepartmentFragmentDoc = gql`
    fragment patientDepartment on Department {
  departmentId
  name
  publicName
  timezone
  phoneNumber
  address {
    ...address
  }
}
    ${AddressFragmentDoc}`;
export const PatientProviderFragmentDoc = gql`
    fragment patientProvider on Provider {
  providerId
}
    `;
export const PatientPracticeFragmentDoc = gql`
    fragment patientPractice on Practice {
  practiceId
  name
}
    `;
export const PatientPatientFragmentDoc = gql`
    fragment patientPatient on Patient {
  patientId
  fullNameDisplay
  firstName
  middleName
  lastName
}
    `;
export const PatientAppointmentTypeFragmentDoc = gql`
    fragment patientAppointmentType on AppointmentType {
  appointmentTypeId
  name
  nameDisplay
}
    `;
export const DetailedPatientAppointmentFragmentDoc = gql`
    fragment detailedPatientAppointment on Appointment {
  ...patientAppointment
  department {
    ...patientDepartment
  }
  provider {
    ...patientProvider
  }
  practice {
    ...patientPractice
  }
  patient {
    ...patientPatient
  }
  appointmentType {
    ...patientAppointmentType
  }
}
    ${PatientAppointmentFragmentDoc}
${PatientDepartmentFragmentDoc}
${PatientProviderFragmentDoc}
${PatientPracticeFragmentDoc}
${PatientPatientFragmentDoc}
${PatientAppointmentTypeFragmentDoc}`;
export const PatientDetailFragmentDoc = gql`
    fragment patientDetail on Patient {
  ...patientOverview
}
    ${PatientOverviewFragmentDoc}`;
export const PracticeReportInputFragmentDoc = gql`
    fragment practiceReportInput on Practice {
  chartTimePerPatient
  lunchEndHour
  lunchStartHour
  lunchEndMinute
  lunchStartMinute
  lunchGap
  regularGap
}
    `;
export const PracticeSlugFragmentDoc = gql`
    fragment practiceSlug on PracticeSlug {
  practiceSlugId
  practiceId
  practiceName
  slug
  managerDashboardUrl
  patientDashboardUrl
  receptionStatusDashboardUrl
}
    `;
export const PracticeDetailFragmentDoc = gql`
    fragment practiceDetail on Practice {
  ...practiceOverview
  ...practiceReportInput
  practiceSlugs {
    nodes {
      ...practiceSlug
    }
  }
}
    ${PracticeOverviewFragmentDoc}
${PracticeReportInputFragmentDoc}
${PracticeSlugFragmentDoc}`;
export const PracticeTableauReportFragmentDoc = gql`
    fragment practiceTableauReport on PracticeTableauReport {
  practiceTableauReportId
  practiceId
  title
  slug
  timePublished
  url
  created
  updated
}
    `;
export const ProviderDepartmentOptionNoIdFragmentDoc = gql`
    fragment providerDepartmentOptionNoId on ProviderDepartmentOption {
  providerId
  practiceId
  departmentId
  appointmentTypeId
  showCheckInButtonInVwr
  showSuggestedArrivalTimeInVwr
  anchorAutoPauseToSuggestedArrivalTime
  patientSiteShowCallButton
  patientSiteShowIntakeForm
  patientSiteShowLocation
  patientSiteIntakeFormLink
  patientSiteContactTranslationId
  patientSiteDirectionsTranslationId
  appointmentPlaceholderMessageTranslationId
  appointmentScheduledMessageTranslationId
  appointmentReminderMessageTranslationId
  appointmentDelayedMessageTranslationId
  appointmentRoomReadyMessageTranslationId
  patientSiteRemoteDirectionsTranslationId
  patientSiteFaqTranslationId
  patientSiteIntakeFormUrlTranslationId
  patientSiteGettingToLinkATranslationId
  patientSiteGettingToLinkBTranslationId
  patientSiteBeforeLinkATranslationId
  patientSiteBeforeLinkBTranslationId
  patientSiteArrivedLinkATranslationId
  patientSiteArrivedLinkBTranslationId
  patientSiteAboutLinkATranslationId
  patientSiteAboutLinkBTranslationId
  patientSiteAfterLinkATranslationId
  patientSiteAfterLinkBTranslationId
  googlePlaceId
  suggestedArrivalTimeMaxDriftMinutes
  patientSiteDisplayPaymentPortal
  patientSiteDisplayPatientPortal
  appointmentsHideOnStatus
  appointmentsHideOnManager
  appointmentsDisableDelayCalc
  patientSiteDisplayLatestSmsSuggestedArrivalTime
  patientSitePracticeSlugId
  autoPauseAfterMinutes
  forcePauseAfterMinutes
  minMinutesBetweenReminderTexts
  patientSiteAboutVideoLinkTranslationId
  useRenderingProvider
  statsType
  persistCheckInToEhr
  useStatsV2
  useMLDurations
  excludeFromViewsAndQueues
  appointmentsAutoTextingDisabled
  noSmsBeforeMinutesIntoDay
  pollPatientChartsOnWaitForIntakeVitals
  pollPatientChartsOnWaitForIntakeAllergies
  pollPatientChartsOnWaitForIntakeEncounterReasons
  pollPatientChartsOnWaitForIntakeProblems
  pollPatientChartsOnWaitForDoctorHpi
  pollPatientChartsOnWaitForDoctorOrders
  pollPatientChartsOnWaitForDoctorEncounterReasons
  pollPatientChartsOnWaitForDoctorReviewOfSystem
  pollPatientChartsOnExamDiagnosis
  canRollbackStatus
  useBetaOverbookedStackingBehavior
  useStatusInferenceByLocation
}
    `;
export const ProviderDepartmentOptionFragmentDoc = gql`
    fragment providerDepartmentOption on ProviderDepartmentOption {
  providerDepartmentOptionId
  ...providerDepartmentOptionNoId
}
    ${ProviderDepartmentOptionNoIdFragmentDoc}`;
export const ServerInfoFragmentDoc = gql`
    fragment serverInfo on ServerInfo {
  name
  uptime
  isProd
  googleMapsApiKey
  hostname
  webhooksServerUrl
}
    `;
export const SlotOverviewFragmentDoc = gql`
    fragment slotOverview on Slot {
  slotId
  practiceId
  providerId
  renderingProviderId
  departmentId
  appointmentTypeId
  timeScheduled
  duration
  isFrozen
  created
  updated
}
    `;
export const TemplateFragmentDoc = gql`
    fragment template on Template {
  templateId
  templateType
  name
  template
  created
  updated
}
    `;
export const LanguageFragmentDoc = gql`
    fragment language on Language {
  languageId
  iso6391Code
  englishName
  enabled
  favorited
}
    `;
export const TranslationFragmentDoc = gql`
    fragment translation on Translation {
  translationId
  overrideTranslationId
  practiceId
  name
  spa
  eng
  translationType
  created
  updated
}
    `;
export const TranslationContentFragmentDoc = gql`
    fragment translationContent on TranslationContent {
  translationId
  content
  languageId
}
    `;
export const EnglishTranslationContentsFragmentDoc = gql`
    fragment englishTranslationContents on Translation {
  englishTranslationContents: translationContents(
    filter: {languageId: {in: ["eng"]}}
  ) {
    nodes {
      ...translationContent
    }
  }
}
    ${TranslationContentFragmentDoc}`;
export const NonEnglishTranslationContentsFragmentDoc = gql`
    fragment nonEnglishTranslationContents on Translation {
  nonEnglishTranslationContents: translationContents(
    filter: {languageId: {notIn: ["eng"]}}
  ) {
    nodes {
      ...translationContent
    }
  }
}
    ${TranslationContentFragmentDoc}`;
export const TranslationDetailFragmentDoc = gql`
    fragment translationDetail on Translation {
  ...translation
  ...englishTranslationContents
  ...nonEnglishTranslationContents
}
    ${TranslationFragmentDoc}
${EnglishTranslationContentsFragmentDoc}
${NonEnglishTranslationContentsFragmentDoc}`;
export const AdminSmsTranslationDetailFragmentDoc = gql`
    fragment adminSmsTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const AdminFaqTranslationDetailFragmentDoc = gql`
    fragment adminFaqTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const AdminContactTranslationDetailFragmentDoc = gql`
    fragment adminContactTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const AdminDirectionsTranslationDetailFragmentDoc = gql`
    fragment adminDirectionsTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const AdminLinkTranslationDetailFragmentDoc = gql`
    fragment adminLinkTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const PracticeTranslationsFragmentDoc = gql`
    fragment practiceTranslations on Practice {
  practiceId
  smsTranslations: translations(condition: {translationType: "SMS"}) {
    nodes {
      ...adminSmsTranslationDetail
    }
  }
  faqTranslations: translations(condition: {translationType: "FAQ"}) {
    nodes {
      ...adminFaqTranslationDetail
    }
  }
  contactTranslations: translations(condition: {translationType: "CONTACT"}) {
    nodes {
      ...adminContactTranslationDetail
    }
  }
  directionsTranslations: translations(condition: {translationType: "DIRECTIONS"}) {
    nodes {
      ...adminDirectionsTranslationDetail
    }
  }
  linkTranslations: translations(condition: {translationType: "LINK"}) {
    nodes {
      ...adminLinkTranslationDetail
    }
  }
}
    ${AdminSmsTranslationDetailFragmentDoc}
${AdminFaqTranslationDetailFragmentDoc}
${AdminContactTranslationDetailFragmentDoc}
${AdminDirectionsTranslationDetailFragmentDoc}
${AdminLinkTranslationDetailFragmentDoc}`;
export const GlobalTranslationsFragmentDoc = gql`
    fragment globalTranslations on Query {
  smsTranslations: translations(
    condition: {translationType: "SMS", practiceId: null}
  ) {
    nodes {
      ...adminSmsTranslationDetail
    }
  }
  faqTranslations: translations(
    condition: {translationType: "FAQ", practiceId: null}
  ) {
    nodes {
      ...adminFaqTranslationDetail
    }
  }
  contactTranslations: translations(
    condition: {translationType: "CONTACT", practiceId: null}
  ) {
    nodes {
      ...adminContactTranslationDetail
    }
  }
  directionsTranslations: translations(
    condition: {translationType: "DIRECTIONS", practiceId: null}
  ) {
    nodes {
      ...adminDirectionsTranslationDetail
    }
  }
  linkTranslations: translations(
    condition: {translationType: "LINK", practiceId: null}
  ) {
    nodes {
      ...adminDirectionsTranslationDetail
    }
  }
}
    ${AdminSmsTranslationDetailFragmentDoc}
${AdminFaqTranslationDetailFragmentDoc}
${AdminContactTranslationDetailFragmentDoc}
${AdminDirectionsTranslationDetailFragmentDoc}`;
export const WaitlistOverviewFragmentDoc = gql`
    fragment waitlistOverview on Waitlist {
  waitlistId
  practiceId
  providerId
  departmentId
  appointmentTypeId
  patientId
  priority
  daysOfWeek
  hourFrom
  hourTo
  note
  created
  updated
}
    `;
export const ProviderDashboardWarningFragmentDoc = gql`
    fragment providerDashboardWarning on ProviderDashboardWarning {
  providerDashboardWarningId
  practiceId
  practice {
    practiceId
    name
  }
  providerId
  provider {
    providerId
    displayName
  }
  managerSiteMessage
  rssSiteMessage
  hideRssSiteDelays
  created
  updated
}
    `;
export const ProviderDashboardWarningsQueryResultFragmentDoc = gql`
    fragment providerDashboardWarningsQueryResult on Query {
  providerDashboardWarnings(
    filter: {or: [{practiceId: {equalTo: $practiceId}}, {practiceId: {isNull: true}}]}
  ) {
    nodes {
      ...providerDashboardWarning
    }
  }
}
    ${ProviderDashboardWarningFragmentDoc}`;
export const ReceptionStatusBaseQueryDocument = gql`
    query ReceptionStatusBaseQuery {
  ...receptionStatusBaseQueryResult
}
    ${ReceptionStatusBaseQueryResultFragmentDoc}`;
export const ReceptionStatusManagerAdminBaseQueryDocument = gql`
    query ReceptionStatusManagerAdminBaseQuery($adminId: String!) {
  ...receptionStatusManagerAdminBaseQueryResult
}
    ${ReceptionStatusManagerAdminBaseQueryResultFragmentDoc}`;
export const ReceptionStatusManagerAutoSwipeDelayQueryDocument = gql`
    query ReceptionStatusManagerAutoSwipeDelayQuery($typeBasedActorId: String!) {
  ...receptionStatusManagerAutoSwipeDelayQueryResult
}
    ${ReceptionStatusManagerAutoSwipeDelayQueryResultFragmentDoc}`;
export const ReceptionStatusManagerOverviewQueryDocument = gql`
    query ReceptionStatusManagerOverviewQuery($managerId: String!) {
  ...receptionStatusManagerOverviewQueryResult
}
    ${ReceptionStatusManagerOverviewQueryResultFragmentDoc}`;
export const ReceptionStatusProviderDelayTimesQueryDocument = gql`
    query ReceptionStatusProviderDelayTimesQuery($practiceId: String!) {
  ...receptionStatusProviderDelayTimesQueryResult
}
    ${ReceptionStatusProviderDelayTimesQueryResultFragmentDoc}`;
export const ReceptionStatusProvidersQueryDocument = gql`
    query ReceptionStatusProvidersQuery($practiceId: String!) {
  ...receptionStatusProvidersQueryResult
}
    ${ReceptionStatusProvidersQueryResultFragmentDoc}`;
export const GlobalProviderDashboardWarningNotifierSubscriptionDocument = gql`
    subscription GlobalProviderDashboardWarningNotifierSubscription {
  ...globalProviderDashboardWarningNotifierSubscriptionResult
}
    ${GlobalProviderDashboardWarningNotifierSubscriptionResultFragmentDoc}`;
export const PracticeProviderDashboardWarningNotifierSubscriptionDocument = gql`
    subscription PracticeProviderDashboardWarningNotifierSubscription {
  ...practiceProviderDashboardWarningNotifierSubscriptionResult
}
    ${PracticeProviderDashboardWarningNotifierSubscriptionResultFragmentDoc}`;
export const ProviderDashboardWarningsQueryDocument = gql`
    query ProviderDashboardWarningsQuery($practiceId: String!) {
  ...providerDashboardWarningsQueryResult
}
    ${ProviderDashboardWarningsQueryResultFragmentDoc}`;